
.code-wrapper{
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    height: 116px;
    img{
        width: 116px;
        height: 116px;
        vertical-align: middle;
    }
    .download-tip{
        margin-top: 16px;
        color: #999;
        margin-left: 12px;
        width: auto;
        text-align: left;
    }
}
