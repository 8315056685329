
.short-linkModule-wrapper{
    padding-right: 40px;
    ::v-deep .el-textarea__inner{
        height: 150px;
        resize: none;
    }
    ::v-deep .el-input__inner{
        background: #F1F0FE;
    }
    .toolbar{
        display: flex;
        align-items: center;
        height: 30px;
        padding: 20px 0;
        .copy-btn{
            background: #FE4800;
            border-radius: 6px;
            color: #fff;
            &:hover,&:focus{
                background: #ff822e;
                border-color: #ff822e;
                color: #FFF;
            }
        }
        .clipboard-tip{

            .clipboard-tip-s{
                display: flex;
                align-items: center;
                color: #91b615;
                margin-left: 10px;
            }
        }
    }
}

