
.immediatePromotion-wrapper{
    display: flex;
    flex-direction: column;
    .immediatePromotion-top{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .top-title{
            color: #444;
            font-size: 18px;
        }
        .btn-blue {
            width:80px;
            background: #574DED;
            border-radius: 4px;
            color: #fff;
            text-align: center;
            line-height: 32px;
            cursor: pointer;
        }
    }
    .immediatePromotion-container{
        height: calc(100vh - 152px );
        display: flex;
            .immediatePromotion-container-left{
                display: flex;;
                flex-direction: column;
                flex: 1;
                background: #fff;
                padding: 24px 0 0 24px;
                -webkit-border-radius: 10px;
                border-radius: 10px;
                box-shadow: 0 0 17px 1px rgba(11, 12, 28, 0.09);
                .immediatePromotion-box{
                    display: flex;
                    align-items: center;
                    margin-bottom: 30px;
                    .form-item-title{
                        min-width: 64px;
                        display: inline-block;
                        color: #444;
                        font-size: 14px;
                        text-align: left;
                        align-self: flex-start;
                    }
                    .immediatePromotion-item{
                        width: 90%;
                        margin-left: 20px;
                        .pic-wrap{
                            display: flex;
                            overflow-y: hidden;
                            .pic-item{
                                margin-right: 10px;
                                height: 200px;
                                .pic {
                                    position: relative;
                                    background: #d4d4d9;
                                    text-align: center;
                                    display: table-cell;
                                    vertical-align: middle;
                                    cursor: pointer;
                                    width: 120px;
                                    height: 120px;
                                    border: 1px solid transparent;
                                    img{
                                        max-height: 130px;
                                        max-width: 130px;
                                    }
                                }
                                .checked{
                                    position: relative;
                                    border: 1px solid #fE4800;
                                    &:after{
                                        position: absolute;
                                        left: 64px;
                                        top: 58px;
                                        font-family: iconfont;
                                        content: "\e677";
                                        color: #fe4800;
                                        font-size: 14px;
                                    }
                                }
                                .default{
                                    &:before{
                                        border:1px solid #FEA100;
                                        border-radius: 4px;
                                        width: 39px;
                                        height: 16px;
                                        line-height: 16px;
                                        position: absolute;
                                        top: 150px;
                                        left: 42px;
                                        content: "默认";
                                        color: #fa8;
                                    }
                                }
                            }
                        }
                        ::v-deep .el-radio__input.is-checked .el-radio__inner {
                            border-color: #FE4800;
                            background: #FE4800;
                        }
                        ::v-deep .el-radio__input.is-checked+.el-radio__label {
                            color: #333;
                        }
                        ::v-deep .el-radio__inner:hover {
                            border-color: #FE4800;
                        }
                        .copywriting-wrapper{
                            margin-top: 20px;
                        }
                    }
                }
            }
            .immediatePromotion-container-right{
                background: #fff;
                margin-left: 20px;
                padding: 20px;
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                box-shadow: 0 0 17px 1px rgba(11, 12, 28, 0.09);
                border-radius: 10px;
                width: 40%;
                .img-wrap {
                    width: 130px;
                    margin-top: 70px;
                    img {
                        border-style: none;
                        width: auto;
                        height: auto;
                        max-width: 100%;
                        vertical-align: middle;
                        -ms-interpolation-mode: bicubic;
                    }
                }
                .text-review {
                    background: #fff;
                    border-radius: 4px;
                    height: 215px;
                    margin: 24px 0;
                    overflow: auto;
                    padding: 0 16px;
                    /*border: 1px solid #e5e5e5;*/
                    ::v-deep .el-textarea__inner{
                        height: 215px;
                        resize: none;
                        border: 1px solid #e5e5e5;
                    }
                }
            }
        }
    .immediatePromotion-list-scrollbar {
        height: 100%;
        ::v-deep .el-scrollbar__wrap {
            overflow-x: hidden;
        }
        ::v-deep .el-textarea__inner{
            height: 150px;
            resize: none;
        }
    }
    .toolbar{
            display: flex;
            align-items: center;
            height: 30px;
            .copy-btn{
                background: #FE4800;
                border-radius: 6px;
                color: #fff;
                &:hover,&:focus{
                    background: #ff822e;
                    border-color: #ff822e;
                    color: #FFF;
                }
            }
            .clipboard-tip{

                .clipboard-tip-s{
                    display: flex;
                    align-items: center;
                    color: #91b615;
                    margin-left: 10px;
                }
            }
        }

}
